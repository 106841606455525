/* BackOfficeUsage.css */

.usage-container {
  display: flex;
  flex-direction: column;
  margin-left: 4vw;
  cursor: pointer; /* Change le curseur en main */
  transition: background-color 0.3s ease; /* Transition douce pour le fond */
  padding: 20px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #333;
  border-style: solid;
}

.usage-container:hover {
  background-color: #333; /* Changer la couleur de fond lors du survol */
}
