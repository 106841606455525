.add-athlete-container {
    background-size: cover;
    min-height: 90vh;
    width: 49.75%;
    border-radius: 10px;
    justify-content: center;
    align-items: flex-end;
    display: flex;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.add-athlete-container-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.2s;

}
.add-athlete-container-image:hover{
    transform: scale(1.1);
}