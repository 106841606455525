.upload-athlete-card-container {
  min-height: 7rem;
  width: 100%;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  align-items: center;
  background-color: #181818;
  cursor: pointer;
}

.upload-athlete-card-container:hover {
  background-color: #282829;
}

.upload-athlete-card-container-selected {
  min-height: 7rem;
  width: 100%;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  align-items: center;
  background-color: #38393c;
  cursor: pointer;
}
