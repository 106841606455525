.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  min-width: 30vw;
  max-width: 50vw;
  min-height: 50vh;
  overflow: auto;
  max-height: 80vh;
}

.container::-webkit-scrollbar {
  width: 2px;
}

.container::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 10px;
  height: 10vh;
}

.container::-webkit-scrollbar-track {
  background-color: transparent;
}
.categoryContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.categoryColumn {
  margin-right: 10px;
  flex: 1;
  justify-content: center;
}

.category {
  margin-bottom: 20px;
}

.category-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.itemButton,
.categoryButton {
  padding: 8px 16px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: 'Space Grotesk', sans-serif;
  box-sizing: border-box;
  padding-right: 32px;
}

.itemButton {
  width: 80%;
}

.categoryButton {
  width: 100%;
}

.itemButton.selected,
.categoryButton.selected {
  background-color: rgba(255, 104, 29, 0.1);
  color: white;
}

.itemButton.disabled,
.categoryButton.disabled {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.4);
  pointer-events: none;
  cursor: not-allowed;
}

.validateIcon {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  fill: #171717;
  stroke: #ff681d;
}

.textInput {
  padding: 8px 16px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 100%;
  font-family: 'Space Grotesk', sans-serif;
  box-sizing: border-box;
  text-align: left;
}
