.register-container {
  height: 100vh;
  width: 98vw;
  margin-top: -3%;
  background-image: url("assets/images/login.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.register-logo-container {
  width: 60%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("assets/images/whiteLogo.png");
  background-repeat: no-repeat;
  background-position: center;
  float: left;
}

.register-form-container {
  background-color: rgba(0, 0, 0, 0.5);
  width: 40%;
  height: 100vh;
  backdrop-filter: blur(10px);
  border-left : 4px solid white;
  position : absolute;
    right : 0;
}
