.cardStyle {
  background: rgba(255, 104, 29, 0.15);
  border: 1px solid rgba(255, 104, 29, 0.4);
  padding: 20px;
  border-radius: 5px;
  width: 40vh;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
  transition: background-color 0.3s ease; /* Smooth transition */
  justify-content: space-between;
}

.cardStyle:hover {
  background-color: #6a72fb50; /* Change background color on hover */
  border-color: #2a35ee; /* Change border color on hover */
  cursor: pointer; /* Change cursor to indicate clickability */
}
