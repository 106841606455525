.multiple-button-container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.multiple-button-container:hover {
  background-color: #282829;
}

.multiple-button-container:hover .multiple-button-text {
  color: white;
}
