.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1vh;
  padding: 1vh;
  cursor: pointer;
  width: 30%;
  transition: background-color 0.3s ease;
  position: relative;
}

.button-delete {
  background-color: #f4354b;
}

.button-delete:hover {
  background-color: #ef1b29;
}

.button-edit {
  background-color: #ff681d;
}

.button-edit:hover {
  background-color: #ff681d20;
}

.button-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  min-width: 20px;
  position: absolute;
  right: 10px;
}

.analysis-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 1vh;
  cursor: pointer;
  margin-bottom: 0.5vh;
  border-width: 1px;
  border-color: #333;
  border-style: solid;
}

.analysis-card.selected {
  background-color: rgba(120, 189, 50, 0.4);
}

.analysis-card:hover {
  background-color: #444;
}

.delete-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 10px;
  padding: 0.5vh;
  cursor: pointer;
}

.delete-icon:hover {
  background-color: #f4354b;
}
