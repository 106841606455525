#form-file-upload {
  position: relative;
}

#input-file-upload {
  display: none;
}


#label-file-upload.horizontal-box {
  height: 60vh;
  width: 100vh;
    display: flex;
  border-width: 2px;
  border-radius: 1rem;
  background-color: #181818;
  background-image: url('assets/images/dragNdrop.png');
  background-repeat: no-repeat;
  background-position: center;
}

#label-file-upload.vertical-box {
  height: 80vh;
  width: 50vh;
    display: flex;

  background-color: #181818;
  background-image: url('assets/images/dragNdrop.png');
  background-repeat: no-repeat;
  background-position: center;
}

#label-file-upload.drag-active {
  background-color: #282829;
  z-index: 2;
}

/* #label-file-upload {
  height: 60vh;
  width: 100vh;
  display: flex;
  border-width: 2px;
  border-radius: 1rem;
  background-color: #181818;
  background-image: url('assets/images/dragNdrop.png');
  background-repeat: no-repeat;
  background-position: center;
}

#label-file-upload.drag-active {
  background-color: #282829;
  background-image: url('assets/images/dragNdrop.png');
  z-index: 2;
} */

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: white;
}

.upload-button:hover {
  text-decoration-line: underline;
}

.video-input-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
