.loading-Card {
  height: 35vh;
  width: 100%;
  max-width: 34rem;
  border-radius: 30px;
  margin: 0;
  background-color: #181818;
  border: 5px solid #181818;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
