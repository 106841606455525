.scroll-parent {
  position: relative;
  height: 100%; /* Ensure proper height */
  width: 100%; /* Ensure proper width */
  overflow: hidden;
}

.scroll-element {
  position: absolute;
  top: 0;
  width: 90%;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: scroll;
  animation-duration: var(
    --animation-duration
  ); /* Use custom property for duration */
}

@keyframes scroll {
  from {
    top: 10%;
  }
  to {
    top: var(--to-value); /* Use custom property for 'to' value */
  }
}
