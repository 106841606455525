.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 1vh;
  padding: 1vh;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  margin-right: 20px;
}

.button-basic {
  background-color: rgba(255, 255, 255, 0.2);
}

.button-basic:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.button-delete {
  background-color: #f4354b;
}

.button-delete:hover {
  background-color: #ef1b29;
}

.icon {
  height: 20px;
  width: 20px;
  min-width: 20px;
}

.button-text {
  font-size: 16px;
}
