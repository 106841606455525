.green-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url('assets/images/analysisGoodVideo.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity:1; 
  
}

/* Barre de progression */
.green-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: rgba(120, 189, 50, 0.15); 
  border-top: 2px solid rgba(120, 189, 50); 
  animation: slide-up 6s forwards; 
}

/* Animation de la montée */
@keyframes slide-up {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}