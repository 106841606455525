.animatedImagesContainer {
    display: flex;
    width: 100%;
  }
  
  .animatedImage {
    width: 50%;
    transition: transform 0.5s ease-in-out;
  }
  
  .front {
    z-index: 1;
  }
  
  .moveRight {
    transform: translateX(50%); /* Adjust as per your Metrics?.screenWidth / 4 equivalent */
  }
  
  .moveLeft {
    transform: translateX(-50%);
  }
  
  .toggleButton {
    /* Your button styling */
  }
  