.athlete-infos-container {
  background-color: #181818;
  border-radius: 10px;
  display: flex;
  position: relative;
  height: 8rem;
  display: flex;
  align-items: center;
  z-index: 2;
}

.athlete-infos-image {
  height: 6rem;
  width: 6rem;
  object-fit: cover;
  border-radius: 100px;
}
