.side-panel-right-hide {
  background: #181818;
  bottom: 0;
  position: fixed;
  right: 0;
  transform: translateX(100%);
  transition: transform 1s;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.side-panel-right-hide--open {
  background: linear-gradient(140deg, rgba(24,24,24,1) 0%, rgba(33,33,33,1) 100%);
  transform: translateX(0%);
}

.side-panel-content {
  overflow-y: auto;
  flex: 1;
  height: 100%;
}