.category-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
  padding: 2vh;
  border-radius: 10px;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.1);
  border-style: solid;
  width: 90%;
  margin: 2vh;
}

.category-container[data-type='usage']:hover,
.category-container[data-type='user']:hover {
  border-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
