/* LoadingCard.css */
.loadingCard-container {
    height : 35vh;
  width: 100%;
  max-width: 34rem;
  border-radius: 30px;
  margin : 0;
  background-color: #181818;
  border: 5px solid #181818;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  }
  
  .loadingCard-imageHeight {
    height: 80%;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative; /* For absolute positioning of child elements */
  }
  
  .loadingCard-bottomStyle {
    background-color: #f5f5f5; /* Example background, adjust as needed */
    flex: 1;
    width: 100%;
  }
  
  .animation {
    position: absolute;
    bottom: 7px;
    height: 170px;
    width: 170px;
    align-self: center;
  }
  
  .backgroundImage {
    height: 100%;
    width: 100%;
  }
  
  .finalizeAnalysis {
    color: black;
    margin: 10px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
  }
  
  .progressText {
    position: absolute;
    bottom: 10px;
    color: #0066cc; /* Example color, adjust as needed */
    text-align: center;
    align-self: center;
  }
  
  .tagName {
    color: white;
    margin-bottom: 5px;
    margin-left: 15px;
    margin-top: 10px;
  }
  