.athlete-card-container {
  min-height: 13vh;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #181818;
}


.pending-athlete-card-container {
  min-height: 13vh;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #181818;
}

.athlete-card-container:hover {
  background-color: #282829;
}
