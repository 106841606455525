.success-card-container:hover {
  background-color: #282829;
  border: 5px solid #282829;
  /* background-color: #38393c; */
}
.success-card-container {
  height: 35vh;
  width: 100%;
  max-width: 34rem;
  border-radius: 30px;
  margin: 0;
  background-color: #181818;
  border: 5px solid #181818;
}

.share-icon-container {
  background: #00000033;
  backdrop-filter: blur(20px);
  height: 5vh;
  width: 5vh;
  position: absolute;
  z-index: 100;
  top: 5%;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.share-icon-container:hover {
  background: #00000066;
}
