.analysis-detail-card-container {
  min-height: 25vh;
  background-color: #181818;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.analysis-detail-card-container:hover {
  background-color: #282829;
}