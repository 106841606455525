.login-button-container {
  margin-top: 10%;
}
.login-container {
  height: 100vh;
  width: 98vw;
  margin-top: -3%;
  background-image: url("assets/images/login.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-logo-container {
  width: 60%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("assets/images/whiteLogo.png");
  background-repeat: no-repeat;
  background-position: center;
  float: right;
}

.login-form-container {
  background-color: rgba(0, 0, 0, 0.5);
  width: 40%;
  height: 100vh;
  backdrop-filter: blur(10px);
  border-right: 4px solid white;
}

.textInput-container {
  background: radial-gradient(100% 700.42% at 98.37% 100%, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 82.29%);
  width: 70%;
  height : 50px;
  margin-top: 10%;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.textInput-container:hover {
  background: radial-gradient(120% 700.42% at 98.37% 100%, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 50.29%);
}


input:-webkit-autofill,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill{
  -webkit-text-fill-color: white !important;
}

/* .login-background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white;
} */
/* .login-box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 40%;
  height: 70%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.login-text {
  width: 70%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
} */
