.item-container {
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  position: relative;
  color: white;
}

.item-container:hover {
  background-color: rgba(128, 128, 128, 0.2);
}

.item-container .trash-icon,
.item-container .plus-icon,
.item-container .pen-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
}

.item-container:hover .trash-icon,
.item-container:hover .plus-icon,
.item-container:hover .pen-icon {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 10px;
  padding: 0.5vh;
  cursor: pointer;
}

.item-container:hover .pen-icon,
.item-container:hover .plus-icon {
  right: 40px;
}

.item-container:hover .trash-icon:hover {
  background-color: #f4354b;
}

.item-container:hover .pen-icon:hover {
  background-color: #ff681d;
}

.item-container:hover .plus-icon:hover {
  background-color: #78bd32;
}
